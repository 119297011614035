var sendAnswerResumeModal = document.querySelectorAll(".sendAnswerResumeModal");
var modalRadioInputAnswer = document.querySelectorAll(
    ".sendAnswerResumeModal .radio_input"
);
var modalTextareaAnswer = document.querySelectorAll(
    ".sendAnswerResumeModal textarea"
);
sendAnswerResumeModal.forEach(function (itemM, iM) {
    itemM.querySelectorAll(".radio_label").forEach(function (item, i) {
        item.addEventListener("click", function () {
            if (modalRadioInputAnswer[i].value == 1) {
                let vacanciesText =
                    "<p>Добрый день!</p> <p>Мы ознакомились с Вашим резюме и сообщаем что Вы нам подходите.</p><p>Свяжитесь с нами по телефону +</p><p>Спасибо!</p>";
                CKEDITOR.instances["welcomeEditor" + iM].setData(vacanciesText);
            } else {
                let vacanciesText =
                    "<p>Добрый день!</p> <p>Мы ознакомились с Вашим резюме и сообщаем что Вы нам не подходите.</p><p>Спасибо!</p>";
                CKEDITOR.instances["welcomeEditor" + iM].setData(vacanciesText);
            }
        });
    });
});
////////////////////////////////////////////
var sendResumeModal = document.querySelectorAll(".sendResumeModal");
var resumeCompressBoxBtn = document.querySelectorAll(
    ".resumeCompressBox__btn span"
);
var sendResumeModalTextarea = document.querySelectorAll(
    ".sendVacanciesModal textarea"
);
var sendResumeCompany = document.querySelector(
    ".header__account__user p"
).innerHTML;
var sendVacansies = document.querySelectorAll(".sendVacanciesModal select");

resumeCompressBoxBtn.forEach(function (item, i) {
    item.addEventListener("click", function () {
        document
            .querySelectorAll(".sendResumeModal")
            [i].querySelector("select")
            .dispatchEvent(new Event("change"));
    });
});

sendVacansies.forEach(function (item, i) {
    item.addEventListener("change", function () {
        let vacanciesText =
            "<p>Добрый день!</p> <p>Компания '" +
            sendResumeCompany +
            "' ищет кандидата на вакансию '" +
            item.options[item.selectedIndex].text +
            "'.</p><p>Если наша вакансия Вас заинтересовала, перезвоните, пожалуйста,  по телефону +</p><p>Спасибо!</p>";

        CKEDITOR.instances["welcomeEditor" + i].setData(vacanciesText);
    });
});

document.addEventListener("DOMContentLoaded", () => {
    sendAnswerResumeModal.forEach(function (itemM, iM) {
        itemM.querySelectorAll(".radio_label").forEach(function (item, i) {
            if (modalRadioInputAnswer[i].value == 1) {
                let vacanciesText =
                    "<p>Добрый день!</p> <p>Мы ознакомились с Вашим резюме и сообщаем что Вы нам подходите.</p><p>Свяжитесь с нами по телефону +</p><p>Спасибо!</p>";
                CKEDITOR.instances["welcomeEditor" + iM].setData(vacanciesText);
                CKEDITOR.instances["welcomeEditor" + iM].updateElement();
            } else {
                let vacanciesText =
                    "<p>Добрый день!</p> <p>Мы ознакомились с Вашим резюме и сообщаем что Вы нам не подходите.</p><p>Спасибо!</p>";
                CKEDITOR.instances["welcomeEditor" + iM].setData(vacanciesText);
                CKEDITOR.instances["welcomeEditor" + iM].updateElement();
            }
        });
    });
    // document.querySelectorAll("textarea").forEach(function (item, i) {
    //     let textareaID = item.getAttribute("id");

    //     CKEDITOR.instances[textareaID].setData(item.value);
    // });
});
